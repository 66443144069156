import React from 'react'
import "./Hero.css"
import {AiOutlineShopping} from "react-icons/ai"
import {HiOutlineShoppingCart} from "react-icons/hi"
import pic from "../../assets/hero.png"
import { motion } from 'framer-motion'

const Hero = () => {
  const transition ={duration : 10 , type : "box"}
  return (
    <>
    <div className="container">
        {/*Left sie */}
        <div className="h-side">
            <span className='text1' >Skin Protection Care</span>
            <div className="text2">
                <span>Trendy Collection</span>
                <span>Hi i am feroz i am learning reacr js</span>
            </div>
        </div>


        {/** Middle side */}
        <div className="wrapper">
          {/** Animation */}
           <motion.div
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
           className="circle"
         
           ></motion.div> 
            <img src={pic} alt=""  width={400} />
          <div className="cart2">
                <AiOutlineShopping/>
            
            <div className="signup"><span>Best signup offer</span>
            < HiOutlineShoppingCart/>
            </div>
            </div>
        </div>

        {/** right side */}

        <div className="h-side">
            <div className="traffic">
            <span>100k</span>
            <span>Montly subcription</span>
            </div>
            <div className="customer">
            <span>100k</span>
            <span>Montly subcription</span>
            </div>

        </div>
    </div>
    
    </>
  )
}

export default Hero