import React from 'react'
import "./Header.css"
import logo from "../../assets/logo.png"
import {BsCartDashFill} from "react-icons/bs"





const Header = () => {
  return (
    <>
    <div className="containere">
        <div className="logo">
            <img src= {logo} alt="" />
            <span className="txt">Amzon</span>
        </div>

      <div className="right">
        <div className="menu">
            <ul className='menu'>
                <li>Collection</li>
                <li>Brands</li>
                <li>Sale</li>
                <li>ENG</li>
                <li>New</li>
            </ul>
        </div>

        <input type="text" className='search' placeholder='search' />
      <BsCartDashFill/>
        </div>
    </div>
    
    
    </>
   
  )
}

export default Header