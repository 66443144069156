import React from 'react'
import "./Footer.css"
import Logo from "../../assets/logo.png"


const Footer = () => {
  return (
    <>
    <div className="cFooterWrapper">
        <hr/>
        <div className="cFooter">
            <div className="Logo">
                <img src={Logo} alt="" />
                <span>Amazon</span>
            </div>

            <div className="block">
               
                    <h1>Contact Us</h1>
                    <div className="detail">
                    <span className="pngLine">
                        icons

                    </span>
                    <span>Hose numer 1146 , chaklalar</span>
                </div>
            </div>
            <div className="block">
               
               <h1>Contact Us</h1>
               <div className="detail">
               <span className="pngLine">
                   icons

               </span>
               <span>Hose numer 1146 , chaklalar</span>
           </div>
       </div>





       <div className="block">
               
               <h1>Contact Us</h1>
               <div className="detail">
               <span className="pngLine">
                   icons

               </span>
               <span>Hose numer 1146 , chaklalar</span>
               
           </div>
           
       </div>



       <div className="block">
               
               <h1>Contact Us</h1>
               <div className="detail">
               <span className="pngLine">
                   icons

               </span>
               <span>Hose numer 1146 , chaklalar</span>
           </div>
       </div>
      
        </div>
        <div className="copywrite">
        <span>Copy Write @2022 by Amazone</span>
        <span>All rights are reserved</span>
       </div>
    </div>
    </>
  )
}

export default Footer