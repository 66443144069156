import React from 'react'
import "./Test.css"
import Hero from "../../assets/testimonialHero.png"
import {Swiper , SwiperSlide} from 'swiper/react'
import { TestimonialsData } from '../../data/testimonials'

const Test = () => {
  return (
    <div className="test">
      <div className="wrap">
        <dvi className="p2">
            <span>Top Rated</span>
            <span> i am feroz and i am learning react js</span>
            </dvi>
        <img src= {Hero} alt="" />

        
       <div className="p2">
        <span>10K</span>
        <span>Happy customer</span>
       </div>
      </div>
 <div className="review">
    <h1>Review</h1>
 </div>

      <div className="carousal">
    <Swiper
    slidesPerView={3}
    spaceBetween={40}
    slidesPerGroup={1}
    className ={"tCoursal"}

    
    
    >
        {
            TestimonialsData.map((date , i)=>{
                return( 
                    <>
                    <SwiperSlide>
                 <div className="teste">
                    <img src={date.image} alt="" />
                    <span>{date.comment}</span>
                    <hr/>
                    <span>{date.name}</span>
                 </div>
                 </SwiperSlide>
                </>
                )
            })

        }
    </Swiper>


    </div>
    </div>
  )
}

export default Test